import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
//공통함수
import setAlertMessage from "../../cmm/cmmAlertMessage"; // 공통함수 Alert창 띄우기
import cmmchangeFormat from "../../cmm/cmmchangeFormat"; // 시간 포맷
import PageBtn from "../../cmm/cmmPageBtn";
import { DatePickerTwo } from "../../cmm/cmmDateFunction";
import AppraisalOutput from "./ReviewModal/AppraisalOutput";
import { Link } from "react-router-dom";
import cmmGetUser from "../../cmm/cmmGetUser";
//전문가 검토
function ReviewExpert() {
  /*=====사용자 인증====*/
  const [sessionData, setSessionData] = useState();
  useEffect(async () => {
    setSessionData(await cmmGetUser());
  }, [sessionStorage.getItem("SESSION")]);
  const ID = sessionData ? sessionData.user : null;
  const AUTH = sessionData ? sessionData.usergrpcd : null;
  /*=============*/
  const server = process.env.REACT_APP_API_SERVER_HOST;
  const { register, reset, watch, handleSubmit } = useForm();

  const [reviewData, setReviewData] = useState();
  const [state, setState] = useState("");
  const [result, setResult] = useState("");
  // 부서코드
  const [lvl, SetLvl] = useState("A");
  // 날짜
  const [date, setDate] = useState();
  // 주소
  const [address, SetAddress] = useState("");
  //조회 결과 page 수 조절 변수
  const [page, setPage] = useState(1);
  // 페이지당 보이는 갯수
  const [count, setCount] = useState(10);
  // 페이지 버튼 리셋
  const [btnRefresh, setBtnRefresh] = useState(true);
  const [appraisalModal, setAppraisalModal] = useState(false);
  const [appraisalModalData, setAppraisalModalData] = useState(false);

  const maxPage = page * count;
  const minpage = page * count - count;

  const [tf, setTf] = useState(false);
  const [start, setStart] = useState(true);

  const [imgPath, setImgPath] = useState([]);

  const [itemData, setItemData] = useState([]);
  const [deptData, setDeptData] = useState([]);
  const [addrData, setAddrData] = useState([]);

  const itemName = (item, type = null) => {
    let itemnm = "";

    if (type === "stat") {
      // status 가져오기
      itemData.map((data) => {
        if (data.itemcd === item) {
          itemnm = data.status;
        }
      });

      // itemnm으로 변환
      itemData.map((data) => {
        if (itemnm === data.itemcd) {
          itemnm = data.itemnm;
        }
      });
    } else {
      itemData.map((data) => {
        if (data.itemcd === item) {
          itemnm = data.itemnm;
        }
      });
    }

    return itemnm;
  };

  const addrName = (addr) => {
    let addrnm = "";
    addrData.map((data) => {
      if (data.itemcd === addr) {
        addrnm = data.itemnm;
      }
    });
    return addrnm;
  };

  const deptName = (dept) => {
    let deptnm = "";

    deptData.map((data) => {
      if (data.deptcd === dept) {
        deptnm = data.deptnm;
      }
    });

    return deptnm;
  };

  // 상태값
  const reviewState = () => {
    let type = [{ value: "null", label: "null" }];
    axios
      .post(`${server}/api/reviewExpert/state`, {
        sessionid: sessionStorage.getItem("SESSION"),
      })
      .then((response) => {
        response.data.map((data) => {
          type.unshift({
            label: data.itemnm,
            value: data.itemcd,
          });
        });
        type.pop(); // 에러방지값 pop()
        type.unshift({
          label: "전체",
          value: "",
        });
      })
      .catch((err) => {});
    return type;
  };

  // 결과값
  const reviewResult = () => {
    let type = [{ value: "null", label: "null" }];
    axios
      .post(`${server}/api/reviewExpert/result`, {
        sessionid: sessionStorage.getItem("SESSION"),
      })
      .then((response) => {
        response.data.map((data) => {
          type.unshift({
            label: data.itemnm,
            value: data.itemcd,
          });
        });
        type.pop(); // 에러방지값 pop()
        type.unshift({
          label: "전체",
          value: "",
        });
      })
      .catch((err) => {});
    return type;
  };

  const reviewAddress = () => {
    let type = [{ value: "null", label: "null" }];
    axios
      .post(`${server}/api/reviewExpert/address`, {
        sessionid: sessionStorage.getItem("SESSION"),
      })
      .then((response) => {
        response.data.map((data) => {
          type.unshift({
            label: data.itemnm,
            value: data.itemcd,
          });
        });
        type.pop(); // 에러방지값 pop()
        type.unshift({
          label: "전체",
          value: "",
        });
      })
      .catch((err) => {});
    return type;
  };

  const reviewDept = () => {
    let type = [{ value: "null", label: "null" }];
    axios
      .post(`${server}/api/reviewExpert/deptcd2`, {
        sessionid: sessionStorage.getItem("SESSION"),
      })
      .then((response) => {
        response.data.map((data) => {
          type.unshift({
            label: data.deptnm,
            value: data.deptcd,
          });
        });
        type.pop(); // 에러방지값 pop()
        type.unshift({
          label: "전체",
          value: "",
        });
      })
      .catch((err) => {});
    return type;
  };

  // 조회
  const onSubmit = (data = { usernm: "" }) => {
    setBtnRefresh(!btnRefresh);
    axios
      .post(`${server}/api/reviewExpert/search`, {
        sessionid: sessionStorage.getItem("SESSION"),
        data: {
          begindt: cmmchangeFormat(date.begindt, "yyMMDD"),
          enddt: cmmchangeFormat(date.enddt, "yyMMDD"),
          firetypecdfinal: result,
          statuscd: state,
          orgcd: lvl,
          createid: data.usernm,
          addrgrp1: address,
        },
      })
      .then((res) => {
        if (res.data.length === 0) {
          setAlertMessage("I004");
        }
        setReviewData(res.data);
      });
  };

  // 처음 한번만 전체 값 받기
  useEffect(() => {
    // itemnm 가져오기
    axios
      .post(`${server}/api/cmm/getItemName`, {
        sessionid: sessionStorage.getItem("SESSION"),
      })
      .then((res) => {
        setItemData(res.data);
      })
      .catch((err) => {});

    axios
      .post(`${server}/api/cmm/getDeptName`, {
        sessionid: sessionStorage.getItem("SESSION"),
      })
      .then((res) => {
        setDeptData(res.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (date && start) {
      onSubmit();
      setStart(false);
    }
  }, [date]);

  const checkDelete = (specimenid) => {
    setAlertMessage("Q900")
      .then((res) => {
        if (res) {
          axios
            .post(`${server}/api/history/deleteSpecimen`, {
              sessionid: sessionStorage.getItem("SESSION"),
              specimenid: specimenid,
            })
            .then((res) => {
              if (res.data) {
                setAlertMessage(res.data);
                onSubmit(watch());
              } else {
                setAlertMessage("E202");
              }
            })
            .catch((err) => {
              setAlertMessage("E202");
            });
        }
      })
      .catch((err) => {
        setAlertMessage("E202");
      });
  };

  return (
    <>
      <p className="manage-title">{window.sessionStorage.getItem("name2")}</p>
      <form className="LoginBox" onSubmit={handleSubmit(onSubmit)}>
        <div
          className="style-searchBox_soloLine"
          style={{ height: "160px", paddingTop: "15px" }}
        >
          <table hover style={{ width: "98%", tableLayout: "fixed" }}>
            <tbody>
              <tr>
                <td style={{ width: "10%" }}>
                  <label>요청일</label>
                </td>
                <td style={{ width: "25%" }}>
                  <div>
                    <DatePickerTwo setDate={setDate} date={date} />
                  </div>
                </td>
                <td style={{ width: "10%" }}>
                  <label>상태</label>
                </td>
                <td style={{ width: "15%" }}>
                  <Select
                    options={reviewState()}
                    onChange={(e) => {
                      setState(e.value);
                    }}
                    defaultValue={{ label: "전체", value: "" }}
                  />
                </td>
                <td style={{ width: "10%" }}>
                  <label>결과</label>
                </td>
                <td style={{ width: "15%" }}>
                  <Select
                    options={reviewResult()}
                    onChange={(e) => {
                      setResult(e.value);
                    }}
                    defaultValue={{ label: "전체", value: "" }}
                  />
                </td>
              </tr>
              <br />
              <tr>
                <td>
                  <label>요청기관</label>
                </td>
                <td>
                  <Select
                    options={reviewDept()}
                    onChange={(e) => {
                      SetLvl(e.value);
                    }}
                    defaultValue={{ label: "전체", value: "" }}
                  />
                </td>
                <td>
                  <label>요청인 성명</label>
                </td>
                <td>
                  <input
                    {...register("usernm", {})}
                    type="text"
                    className="form-control"
                    style={{ margin: "0px", width: "100%" }}
                    maxLength={100}
                  />
                </td>
                <td>
                  <label>주소지</label>
                </td>
                <td>
                  <Select
                    options={reviewAddress("deptcd", 2, "A")}
                    onChange={(e) => {
                      SetAddress(e.value);
                    }}
                    defaultValue={{ label: "전체", value: "" }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <Button
          className="btn-search"
          type="submit"
          style={{ float: "right", marginRight: "5px" }}
          onClick={() => {
            setTf(true);
          }}
        >
          <FontAwesomeIcon icon={faSearch} /> 조회
        </Button>
        <Table bordered hover>
          <thead className="style-tHeader">
            <tr style={{ verticalAlign: "middle" }}>
              <th rowSpan="2" style={{ width: "9%" }}>
                <label>요청시점</label>
              </th>
              <th rowSpan="2" style={{ width: "9%" }}>
                <label>요청자</label>
              </th>
              <th rowSpan="2" style={{ width: "9%" }}>
                <label>요청기관</label>
              </th>
              <th rowSpan="2" style={{ width: "20%" }}>
                <label>화재장소</label>
              </th>
              <th colSpan="2" style={{ width: "15%" }}>
                <label>화재원인</label>
              </th>
              <th rowSpan="2" style={{ width: "6%" }}>
                <label>상태</label>
              </th>
              <th rowSpan="2" style={{ width: "6%" }}>
                <label>세부상태</label>
              </th>
              <th rowSpan="2" style={{ width: "5%" }}>
                <label>상세보기</label>
              </th>
              <th rowSpan="2" style={{ width: "5%" }}>
                <label>감정서출력</label>
              </th>
              <th rowSpan="2" style={{ width: "5%" }}>
                <label>삭제</label>
              </th>
            </tr>
            <tr>
              <th style={{ width: "5%" }}>
                <label>인공지능</label>
              </th>
              <th style={{ width: "5%" }}>
                <label>전문가</label>
              </th>
            </tr>
          </thead>
          <tbody>
            {reviewData
              ? reviewData.slice(minpage, maxPage).map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {cmmchangeFormat(data.createtm, "yy-MM-DD HH:mm")}
                      </td>
                      <td>{data.createid}</td>
                      <td>{deptName(data.orgcd)}</td>
                      <td>{`${data.addrtxt1} ${data.addrtxt2}`}</td>
                      <td>{itemName(data.firetypecd1)}</td>
                      <td>{itemName(data.firetypecd2)}</td>
                      <td>{itemName(data.statuscd, "stat")}</td>
                      <td
                        style={
                          data.statuscd === "EY" && data.is_stamp
                            ? { backgroundColor: "#5FBB01", color: "#FFFFFF" }
                            : {}
                        }
                      >
                        {itemName(data.statuscd)}
                      </td>

                      <td>
                        <Link
                          to={{
                            pathname: "/reviewReport",
                            state: { data: data },
                          }}
                        >
                          <Button
                            style={{ width: "100%" }}
                            className="btn-table"
                            variant="secondary"
                          >
                            보기
                          </Button>
                        </Link>
                      </td>
                      <td>
                        {data.statuscd === "FY" ||
                        data.statuscd === "AY" ||
                        data.statuscd === "EY" ? (
                          <Button
                            onClick={() => {
                              setAppraisalModalData(data);
                              setAppraisalModal(true);
                            }}
                            style={{ width: "100%" }}
                            className="btn-table"
                            variant="secondary"
                          >
                            보기
                          </Button>
                        ) : null}
                      </td>
                      <td>
                        {data.statuscd === "FI" || data.statuscd === "AX" ? (
                          <Button
                            onClick={() => {
                              checkDelete(data.specimenid);
                            }}
                            style={{ width: "100%" }}
                            className="btn-table_delete"
                          >
                            삭제
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="11" style={{ borderBottom: "none" }}>
                <PageBtn
                  data={reviewData ? reviewData : 0}
                  page={page}
                  setPage={setPage}
                  count={count}
                  btnRefresh={btnRefresh}
                />
              </td>
            </tr>
          </tfoot>
        </Table>
      </form>
      {appraisalModal ? (
        <AppraisalOutput
          TF={appraisalModal}
          setTF={setAppraisalModal}
          data={appraisalModalData}
          imgPath={imgPath}
        />
      ) : null}
    </>
  );
}

export default ReviewExpert;
